import { FormGroup } from "@material-ui/core";
import CustomButton from "../utils/CustomButton";
import CustomInput from "../utils/CustomInput";
import { useEffect, useState } from "react";
import myGreAxios from "../utils/axiosConfig";
import LoadingIcon from "../utils/LoadingIcon";

const Login = (props) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const LOGIN_URL = "token/";
  const handleUserNameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const loadingIcon = <LoadingIcon />;
  const logIn = () => {
    if (username && password) {
      setButtonClicked(true);
      myGreAxios
        .post(LOGIN_URL, { username: username, password: password })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("access", response.data.access);
            setButtonClicked(false);
            setErrorMessage(null);
            props.onLoggedIn();
          } else {
            setButtonClicked(false);
          }
        })
        .catch((error) => {
          setUsername(null);
          setPassword(null);
          setButtonClicked(false);
          setErrorMessage(error.message);
        });
    } else {
      setErrorMessage("All fields are required.");
      return null;
    }
  };
  let dark = props.dark;
  if (buttonClicked) {
    return loadingIcon;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "400px",
          maxWidth: "400px",
        }}
      >
        <FormGroup>
          <CustomInput
            onChange={handleUserNameChange}
            dark={dark}
            label="Username"
            variant="outlined"
            color="primary"
          />
          <CustomInput
            onChange={handlePasswordChange}
            dark={dark}
            label="Password"
            variant="outlined"
            type="password"
            color="primary"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: dark ? "#f000ff" : "#FF0000",
            }}
          >
            {errorMessage ? errorMessage : null}
          </div>
          <CustomButton
            onClick={logIn}
            dark={dark}
            variant="contained"
            color="primary"
            text="Login"
          />
        </FormGroup>
      </div>
    </div>
  );
};
export default Login;
