import Loader from "react-loader-spinner";

const LoadingIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "400px",
          maxWidth: "400px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >Please wait. Communicating with server.</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Loader type="ThreeDots" color="#fb0" height="100" width="100" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingIcon;
