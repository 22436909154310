import axios from "axios";

var baseURL = "http://192.168.1.3:8000/api/v1/";
if (process.env.NODE_ENV === "production") baseURL = "https://my-gre.herokuapp.com/api/v1/";

const myGreAxios = axios.create({
  baseURL: baseURL,
});

export default myGreAxios;
