import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Input,
  makeStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import myGreAxios from "../utils/axiosConfig";

const CustomAccordion = ({
  onDelete,
  onChange,
  data = {
    id: 1,
    word: "GG",
    meaning: "Good Game",
    sentence: "This Sniper is so noob....GG!",
    status: false,
    hidden: false,
  },
  expanded = false,
  panelId = "1",
  dark = false,
}) => {
  let backgroundColor = dark ? "#000000" : "#FFFFFF";
  const [editable, setEditable] = useState(false);
  const [word, setWord] = useState(null);
  const [meaning, setMeaning] = useState(null);
  const [sentence, setSentence] = useState(null);
  const [status, setStatus] = useState(data.status ? data.status : false);

  const onWordChange = (event) => {
    event.preventDefault();
    setWord(event.target.value);
  };
  const onMeaningChange = (event) => {
    event.preventDefault();
    setMeaning(event.target.value);
  };
  const onSentenceChange = (event) => {
    event.preventDefault();
    setSentence(event.target.value);
  };
  const startEditing = (event) => {
    event.preventDefault();
    setEditable(true);
  };
  const discardChanges = (event) => {
    event.preventDefault();
    setEditable(false);
  };
  // const parseUserId = (token) => {
  //   try {
  //     let jsonData = JSON.parse(atob(token.split(".")[1]));
  //     return jsonData.user_id;
  //   } catch (e) {
  //     return null;
  //   }
  // };
  const accessToken = localStorage.getItem("access");
  const API_URL = "dictionary/" + data.id + "/";
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const saveChanges = (event) => {
    event.preventDefault();
    if (word || meaning || sentence) {
      let customObject = {};
      if (word) {
        customObject["word"] = word;
      }
      if (meaning) {
        customObject["meaning"] = meaning;
      }
      if (sentence) {
        customObject["sentence"] = sentence;
      }
      myGreAxios
        .patch(API_URL, customObject, config)
        .then((response) => {
          if (response.status === 200) {
            data.word = word ? word : data.word;
            data.meaning = meaning ? meaning : data.meaning;
            data.sentence = sentence ? sentence : data.sentence;
            setWord(null);
            setMeaning(null);
            setSentence(null);
            setEditable(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteItem = (event) => {
    event.preventDefault();
    myGreAxios
      .patch(API_URL, { hidden: true }, config)
      .then((response) => {
        data.hidden = true;
        onDelete();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeStatus = (event, currentStatus = false) => {
    event.preventDefault();
    if (currentStatus !== status) {
      myGreAxios
        .patch(API_URL, { status: currentStatus }, config)
        .then((response) => {
          if (response.status === 200) {
            data.status = currentStatus;
            setStatus(currentStatus);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // useEffect(() => {
  //   setReloadTrigger(!reloadTrigger);
  // }, [reloadTrigger, hidden]);
  const useStyles = makeStyles((theme) => ({
    root: {
      color: dark ? "#0fb" : "#000000",
      backgroundColor: backgroundColor,
      border: data.status
        ? dark
          ? "5px solid #fb0"
          : "5px solid #43a047"
        : dark
        ? "5px solid #f000ff"
        : "5px solid #ff0000",
      margin: "10px",
    },
    rounded: {
      square: false,
    },
    inputRoot: {
      color: dark ? "#0fb" : "#000000",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();

  return (
    <Accordion
      expanded={expanded === panelId}
      onChange={onChange}
      classes={{
        root: classes.root,
        rounded: classes.rounded,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {expanded && editable ? (
          <Input
            onChange={onWordChange}
            classes={{ root: classes.inputRoot }}
            style={{
              width: "100%",
            }}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            value={data.word && !word ? data.word : word}
            className={classes.heading}
          >
            {data.word ? data.word : null}
          </Input>
        ) : (
          <Typography className={classes.heading}>
            {data.word ? data.word : null}
          </Typography>
        )}
        {expanded && editable ? (
          <Input
            onChange={onMeaningChange}
            classes={{ root: classes.inputRoot }}
            style={{
              width: "100%",
            }}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            value={data.meaning && !meaning ? data.meaning : meaning}
            className={classes.secondaryHeading}
          >
            {data.meaning ? data.meaning : null}
          </Input>
        ) : (
          <Typography className={classes.secondaryHeading}>
            {data.meaning ? data.meaning : null}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {expanded && editable ? (
              <Input
                onChange={onSentenceChange}
                classes={{ root: classes.inputRoot }}
                style={{
                  width: "100%",
                }}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                placeholder={"Example sentence"}
                value={data.sentence && !sentence ? data.sentence : sentence}
              />
            ) : (
              <Typography>{data.sentence ? data.sentence : null}</Typography>
            )}
            {editable && expanded ? null : (
              <div>
                <Edit
                  onClick={startEditing}
                  style={{
                    cursor: "pointer",
                    margin: "5px",
                  }}
                />
                <Delete
                  onClick={deleteItem}
                  style={{
                    cursor: "pointer",
                    margin: "5px",
                  }}
                />
              </div>
            )}
          </div>

          {editable && expanded ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 10px 10px 10px",
              }}
            >
              <Button
                onClick={discardChanges}
                color="secondary"
                variant="outlined"
              >
                Discard
              </Button>
              <Button onClick={saveChanges} color="primary" variant="outlined">
                Save
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 10px 10px 10px",
              }}
            >
              <Button
                onClick={(event) => changeStatus(event, false)}
                color="secondary"
                variant="outlined"
              >
                I dont
              </Button>
              <Button
                onClick={(event) => changeStatus(event, true)}
                color="primary"
                variant="outlined"
              >
                I Know
              </Button>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const CustomCard = ({
  datalist = [
    {
      id: 1,
      word: "GG",
      meaning: "Good Game",
      sentence: "This Sniper is so noob....GG!",
      status: false,
      hidden: false,
    },
    {
      id: 2,
      word: "WP",
      meaning: "Well Played",
      sentence: "This Sniper is so noob....GG!",
      status: true,
      hidden: false,
    },
  ],
  dark = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDeletion = () => {
    setExpanded(false);
  }
  useEffect(() => {
    setExpanded(false);
  }, []);
  if (datalist) {
    return (
      <div>
        {datalist
          .filter((data) => {
            return !data.hidden;
          })
          .map((data) => {
            return (
              <CustomAccordion
                onDelete={handleDeletion}
                onChange={handleChange(data.id)}
                data={data}
                expanded={expanded}
                panelId={data.id}
                dark={dark}
              />
            );
          })}
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default CustomCard;
