import "./App.css";
import Login from "./components/login/Login";
import { Button, FormGroup, ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import NavBar from "./components/navbar/NavBar";
import CustomCard from "./components/card/CustomCard";
import myGreAxios from "./components/utils/axiosConfig";
import CustomInput from "./components/utils/CustomInput";
import CustomButton from "./components/utils/CustomButton";
import LoadingIcon from "./components/utils/LoadingIcon";

const App = () => {
  const [darkState, setDarkState] = useState(true);
  const toggleDarkMode = () => {
    setDarkState(!darkState);
  };
  const [dictionaryData, setDictionaryData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const logIn = () => {
    setLoggedIn(true);
  };
  const [errorMessage, setErrorMessage] = useState(null);
  const [dataAddingPhase, setDataAddingPhase] = useState(false);
  const initDataAdding = () => {
    setDataAddingPhase(true);
  };
  const handleDiscardData = () => {
    setDataAddingPhase(false);
  };
  const [word, setWord] = useState(null);
  const [apiRunning, setApiRunning] = useState(false);
  const [meaning, setMeaning] = useState(null);
  const [sentence, setSentence] = useState(null);
  const handleWord = (event) => {
    setWord(event.target.value);
  };
  const handleMeaning = (event) => {
    setMeaning(event.target.value);
  };
  const handleSentence = (event) => {
    setSentence(event.target.value);
  };
  const API_URL = "dictionary/";
  const accessToken = localStorage.getItem("access");
  const fetchData = () => {
    if (accessToken) {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      setApiRunning(true);
      myGreAxios
        .get(API_URL, config)
        .then((response) => {
          if (response.status === 200) {
            setDictionaryData(response.data);
            setApiRunning(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setApiRunning(false);
        });
    }
  };

  const addData = () => {
    if (accessToken && word && meaning) {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      setApiRunning(true);
      myGreAxios
        .post(
          API_URL,
          { word: word, meaning: meaning, sentence: sentence },
          config
        )
        .then((response) => {
          if (response.status === 201) {
            setDataAddingPhase(false);
            fetchData();
          }
        })
        .catch((error) => {
          console.log(error);
          setApiRunning(false);
          setErrorMessage(error.message);
        });
    }
  };

  const addingElement = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "400px",
          maxWidth: "400px",
        }}
      >
        <FormGroup>
          <CustomInput
            onChange={handleWord}
            dark={darkState}
            label="Word"
            variant="outlined"
            color="primary"
          />
          <CustomInput
            onChange={handleMeaning}
            dark={darkState}
            label="Meaning"
            variant="outlined"
            color="primary"
          />
          <CustomInput
            onChange={handleSentence}
            dark={darkState}
            label="Sentence"
            variant="outlined"
            color="primary"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: darkState ? "#f000ff" : "#FF0000",
            }}
          >
            {errorMessage ? errorMessage : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              onClick={handleDiscardData}
              dark={darkState}
              variant="contained"
              color="secondary"
              text="Discard"
              fullWidth={true}
            />
            <CustomButton
              style={{
                flexGrow: 1,
              }}
              onClick={addData}
              dark={darkState}
              variant="contained"
              color="primary"
              text="Add"
              fullWidth={true}
            />
          </div>
        </FormGroup>
      </div>
    </div>
  );

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.clear();
  };

  const backgroundColor = darkState ? "#000000" : "#FFFFFF";
  const textColor = darkState ? "#0ff" : "#000000";
  const darkTheme = createMuiTheme({
    palette: {
      secondary: {
        main: "#f000ff",
      },
      primary: {
        main: "#fb0",
      },
      type: "dark",
      multilineColor: {
        color: "#0ff",
      },
    },
  });
  const lightTheme = createMuiTheme();
  const theme = darkState ? darkTheme : lightTheme;

  useEffect(() => {
    const accessToken = localStorage.getItem("access");
    if (accessToken) {
      setLoggedIn(true);
      fetchData(loggedIn);
    }
  }, [accessToken]);
  const loadingIcon = <LoadingIcon />;
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          background: backgroundColor,
          color: textColor,
          height: "100vh",
        }}
      >
        <NavBar
          handleLogout={handleLogout}
          loggedIn={loggedIn}
          darkChecked={darkState}
          darkChanged={toggleDarkMode}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "45em",
              width: "100%",
              padding: "5px",
              overflowY: "auto",
            }}
          >
            {apiRunning ? (
              loadingIcon
            ) : loggedIn ? (
              dataAddingPhase ? (
                addingElement
              ) : (
                <CustomCard datalist={dictionaryData} dark={darkState} />
              )
            ) : (
              <Login onLoggedIn={logIn} dark={darkState} />
            )}
          </div>
        </div>
        <div
          style={{
            color: darkState ? "black" : "white",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: darkState ? "#fb0" : "#3f51b5",
            height: "50px",
          }}
        >
          {loggedIn ? (
            <Button onClick={initDataAdding} color="inherit" variant="outlined">
              +
            </Button>
          ) : null}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
