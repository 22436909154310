import React from "react";
import { Button } from "@material-ui/core";

const CustomButton = ({
  variant,
  color = "inherit",
  onChange,
  onClick,
  text = "Button",
  dark = false,
  fullWidth = false,
}) => {
  // let buttonColor = dark? color: "primary";
  return (
    <Button
      type="submit"
      style={{ margin: "15px", padding: "15px" }}
      onChange={onChange}
      onClick={onClick}
      color={color}
      variant={variant}
      fullWidth={fullWidth}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
