import React from "react";
import { TextField } from "@material-ui/core";

const CustomInput = ({
  type = "text",
  label,
  placeholder = label,
  variant,
  color = "inherit",
  dark = false,
  onChange,
}) => {
  let inputColor = dark ? "#0ff" : "#000000";
  return (
    <TextField
      style={{ margin: "15px" }}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      label={label}
      color={color}
      variant={variant}
      InputLabelProps={{ style: { color: inputColor } }}
      InputProps={{ style: { color: inputColor } }}
    />
  );
};

export default CustomInput;
